import { Center, Heading, Text } from "@chakra-ui/react";

export const Portfolio = () => {
  return (
    <Center data-section-id="portfolio" id="portfolio" h="600px" flexDir="column">
      <Heading as="h1" size="4xl" fontFamily="Bebas Neue" fontWeight={600} color="secondary" textAlign="center">
        Portfolio
      </Heading>
      <Text mt={5} fontSize="xl" fontFamily="Bebas Neue" color="secondary" textAlign="center">
        Coming soon!
      </Text>
    </Center>
  );
};
