import { Center, Grid, GridItem, Heading, Image, Text } from "@chakra-ui/react";

export const MyValues = () => {
  return (
    <Grid data-section-id="my-values" id="my-values" templateRows="auto" templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} bgColor="primary">
      {/* Mission Image */}
      <GridItem colSpan={1} order={{ base: 1, md: 2 }}>
        <Image src="https://images.unsplash.com/photo-1729903283946-43ea5f53e123?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw3MHx8fGVufDB8fHx8fA%3D%3D" alt="values 1" height="100%" width="100%" objectFit="cover" />
      </GridItem>

      {/* Mission Section */}
      <GridItem p={10} as={Center} flexDir="column" alignItems="flex-start" colSpan={1} order={{ base: 2, md: 1 }}>
        <Heading as="h1" size="2xl" fontFamily="Bebas Neue" fontWeight={600} color="secondary" textAlign="center">
          Mission
        </Heading>
        <Text mt={5} fontSize="2xl">
          My mission is to empower beauty, fashion, skincare, and lifestyle brands with impactful social media strategies and visually captivating content. I strive to help brands authentically connect with their audiences, building engagement and brand loyalty through storytelling, consistency, and creativity.
        </Text>
      </GridItem>

      {/* Vision Image */}
      <GridItem colSpan={1} order={{ base: 3, md: 3 }}>
        <Image src="https://images.unsplash.com/photo-1729903283946-43ea5f53e123?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDh8RnpvM3p1T0hONnd8fGVufDB8fHx8fA%3D%3D" alt="values 1" height="100%" width="100%" objectFit="cover" />
      </GridItem>

      {/* Vision Section */}
      <GridItem p={10} as={Center} flexDir="column" alignItems="flex-start" colSpan={1} order={{ base: 4, md: 4 }}>
        <Heading as="h1" size="2xl" fontFamily="Bebas Neue" fontWeight={600} color="secondary" textAlign="center">
          Vision
        </Heading>
        <Text mt={5} fontSize="2xl">
          To be a trusted partner for brands in beauty and lifestyle, known for elevating their social media presence and driving meaningful growth. I envision a future where every brand, big or small, has the tools and strategies to confidently navigate the digital world and reach their fullest potential.
        </Text>
      </GridItem>
    </Grid>
  );
};
