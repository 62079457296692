import React, { useEffect, useState } from "react";
import { Center, Flex, Heading, IconButton } from "@chakra-ui/react";
import { pages } from "../constants";
import { useAppContext } from "../context/AppContext";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import ReactGA from "react-ga4";

export const Sidebar: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<string>(pages[0].name);
  const [nextSection, setNextSection] = useState<string>(pages[1]?.name || "");
  const [previousSection, setPreviousSection] = useState<string>("");

  const { currentSectionId } = useAppContext();

  useEffect(() => {
    if (currentSectionId) {
      // Find the index of the current section
      const currentIndex = pages.findIndex((page) => page.id === currentSectionId);

      if (currentIndex !== -1) {
        // Get the current, next, and previous sections based on the index
        const current = pages[currentIndex];
        const next = pages[currentIndex + 1];
        const previous = pages[currentIndex - 1];

        // Set the current, next, and previous section names
        setCurrentSection(current?.name || "");
        setNextSection(next?.name || "");
        setPreviousSection(previous?.name || "");
      }
    }
  }, [currentSectionId]);

  const onArrowPress = (direction: "up" | "down") => {
    const targetSection = direction === "up" ? previousSection : nextSection;
    const targetId = pages.find((page) => page.name === targetSection)?.id;

    if (targetId) {
      const element = document.getElementById(targetId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (currentSection) {
      ReactGA.send({ hitType: "pageview", page: `/${currentSection}`, title: currentSection });
    }
  }, [currentSection]);

  return (
    <Flex flexDir="column" bg="primary" h="90vh">
      <Center h="50%" flexDir="column">
        <IconButton onClick={() => onArrowPress("up")} mb={20} _hover={{ bg: "transparent" }} aria-label="go-up" icon={<FiArrowUp />} color="secondary" variant="ghost" />
        <Heading as="h1" transform="rotate(-90deg)" whiteSpace="nowrap" size="xl" fontFamily="Bebas Neue" fontWeight={600} color="secondary" textAlign="center">
          {previousSection}
        </Heading>
      </Center>
      <Center h="50%" flexDir="column">
        <Heading as="h1" transform="rotate(-90deg)" whiteSpace="nowrap" size="xl" fontFamily="Bebas Neue" fontWeight={600} color="secondary" textAlign="center">
          {currentSection}
        </Heading>
        <IconButton onClick={() => onArrowPress("down")} mt={20} _hover={{ bg: "transparent" }} aria-label="go-down" icon={<FiArrowDown />} color="secondary" variant="ghost" />
      </Center>
    </Flex>
  );
};
