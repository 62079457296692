export const pages = [
  { name: "Home", id: "home", hideNavbar: true },
  { name: "About Me", id: "about-me" },
  { name: "My Value", id: "my-values" },
  { name: "Social Media", id: "social-media" },
  { name: "Why?", id: "why" },
  { name: "portfolio", id: "portfolio" },
  { name: "Service", id: "service" },
  { name: "Contact", id: "contact" },
];
