import { Center, Grid, GridItem, Heading, Image } from "@chakra-ui/react";

export const SocialMedia = () => {
  return (
    <Grid data-section-id="social-media" id="social-media" gap={5} p={10} templateRows="auto auto auto" w="100%" height="auto">
      <Center>
        <Heading as="h1" size="4xl" fontFamily="Bebas Neue" fontWeight={600} color="secondary" textAlign="center">
          Social Media
        </Heading>
      </Center>

      {/* 3x3 Image Grid */}
      <GridItem as={Grid} templateColumns={{ base: "1fr", md: "1fr 1fr" }} px={{ base: "10%", md: "20%" }} gap={10}>
        {/* First Image Grid */}
        <GridItem as={Grid} templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(3, 1fr)" }} templateRows={{ base: "auto", md: "repeat(3, auto)" }} gap={2}>
          {Array(9)
            .fill("")
            .map((_, index) => (
              <Image key={index} src="https://images.unsplash.com/photo-1717226263533-d48d50759764?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDh8RnpvM3p1T0hONnd8fGVufDB8fHx8fA%3D%3D" alt={`about-image-${index}`} borderRadius="md" height="auto" width="100%" objectFit="cover" />
            ))}
        </GridItem>

        {/* Second Image Grid */}
        <GridItem as={Grid} templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(3, 1fr)" }} templateRows={{ base: "auto", md: "repeat(3, auto)" }} gap={2}>
          {Array(9)
            .fill("")
            .map((_, index) => (
              <Image key={index} src="https://images.unsplash.com/photo-1717226263533-d48d50759764?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDh8RnpvM3p1T0hONnd8fGVufDB8fHx8fA%3D%3D" alt={`about-image-${index}`} borderRadius="md" height="auto" width="100%" objectFit="cover" />
            ))}
        </GridItem>
      </GridItem>

      {/* Single Row of Four Images */}
      <GridItem as={Grid} gap={5} px={{ base: 5, md: 10 }} templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }}>
        {Array(4)
          .fill("")
          .map((_, index) => (
            <GridItem key={index} p={5}>
              <Image src="https://images.unsplash.com/photo-1717226263533-d48d50759764?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDh8RnpvM3p1T0hONnd8fGVufDB8fHx8fA%3D%3D" alt={`about-image-${index}`} borderRadius="xl" height="auto" width="100%" objectFit="cover" />
            </GridItem>
          ))}
      </GridItem>
    </Grid>
  );
};
